import { SANDBOX_MODE } from '@/lib/defaults';

/* eslint-disable camelcase */
export const StripePlans = [
    {
        id: 'monthly',
        name: 'Monthly',
        amount: '$25',
        duration: 'month',
        recurring: true,
        price: '$25/month',
        priceId: SANDBOX_MODE ? 'price_1P3OsUJCkvkbxYUrkL3WAADZ' : 'price_1P3P0qJCkvkbxYUrqdmJBo60',
        paypalPlanID: SANDBOX_MODE ? 'P-84V54847WH8263425MWS6G5Q' : 'P-8KF42066D24202242M6IPVVY',
        notice_text2: 'Cancel easily any time.'

    },
    {
        id: '3months',
        name: 'For 3 Months',
        amount: '$45',
        duration: 'for a 3 month subscription',
        recurring: true,
        price: '$45/year',
        priceId: SANDBOX_MODE ? 'price_1P3OupJCkvkbxYUrPa4o6hMw' : 'price_1P3P0EJCkvkbxYUr1WttPR8l',
        paypalPlanID: SANDBOX_MODE ? 'P-84V54847WH8263425MWS6G5Q' : 'P-2D172933DX1299532M6IPWPY',
        notice_text2: 'Cancel easily any time.'
    },
    {
        id: 'annual',
        name: 'Annually',
        amount: '$105',
        duration: 'year',
        recurring: true,
        savings: 'SAVE 37%',
        savings_mobile: 'SAVE 37%',
        price: '$105/year',
        priceId: SANDBOX_MODE ? 'price_1QzLsCJCkvkbxYUrLOCntVmZ' : 'price_1P3P1NJCkvkbxYUr6Uvq0BgE',
        paypalPlanID: SANDBOX_MODE ? 'P-5LW3091638637694MM7EI7NI' : 'P-2HA45753DG383363SM6IPXBY',
        notice_text2: 'Cancel easily any time.'
    },
    {
        id: 'times_tables',
        name: 'Times Tables',
        amount: '$9.99',
        duration: '99 days',
        recurring: false,
        price: '$9.99',
        priceId: SANDBOX_MODE ? 'price_1PgTRqJCkvkbxYUr2d6KaZM7' : 'price_1PgTQkJCkvkbxYUr0SM71Y7y',
        paypalPlanID: SANDBOX_MODE ? 'P-5M24184953393334XM2UTT6A' : 'P-24B361596W6748911M2UTPOA'
    }
];

export const StripeStudents = [
    {
        id: '50 students',
        name: 'Up to 50	students',
        recurring: true,
        plans: [
            {
                id: 'monthly',
                name: '1 Month',
                name_planButton: '1 Month',
                amount: '$10.99',
                duration: 'for a monthly subscription',
                recurring: true,
                savings: 'START NOW',
                notice_text: 'for a 1-month Muzology subscription.',
                notice_text2: 'Easily cancel within a month, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtblRJCkvkbxYUrbcBUvdPZ' : 'price_1Oz31vJCkvkbxYUrDWVQc1Fa',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-1UG07954A2921821XMYTTVNY'
            },
            {
                id: '3month',
                name: '3 Months',
                name_planButton: '3 Months',
                amount: '$27.00',
                duration: 'for a 3 month subscription',
                recurring: true,
                savings: 'SAVE MORE!',
                notice_text: 'for a 3-month Muzology subscription.',
                notice_text2: 'Easily cancel within three months, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtboYJCkvkbxYUrYlPUJdv0' : 'price_1Oz31tJCkvkbxYUrvvMts6Oj',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-8RB797571S320122UMYTUHVQ'
            },
            {
                id: 'annual',
                name: '1 Year',
                name_planButton: '1 Year',
                amount: '$92.00',
                duration: 'for an annual subscription',
                recurring: true,
                savings: 'BEST VALUE',
                notice_text: 'for a 1-year Muzology subscription.',
                notice_text2: 'Easily cancel and you won’t be charged for the next annual subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbpqJCkvkbxYUr3VJESWi9' : 'price_1Oz31rJCkvkbxYUrC0FZL8RV',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-57T890375M4548927MYTUF7Q'
            }
        ]
    },
    {
        id: '100 students',
        name: 'Up to 100	students',
        recurring: true,
        plans: [
            {
                id: 'monthly',
                name: '1 Month',
                name_planButton: '1 Month',
                amount: '$16.99',
                duration: 'for a monthly subscription',
                recurring: true,
                savings: 'START NOW',
                notice_text: 'for a 1-month Muzology subscription.',
                notice_text2: 'Easily cancel within a month, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1Otbr3JCkvkbxYUr90QiK1mV' : 'price_1Oz31pJCkvkbxYUr0MOm6XBC',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-4CD241596M7927907MYTTV3Q'
            },
            {
                id: '3month',
                name: '3 Months',
                name_planButton: '3 Months',
                amount: '$45.00',
                duration: 'for a 3 month subscription',
                recurring: true,
                savings: 'SAVE MORE!',
                notice_text: 'for a 3-month Muzology subscription.',
                notice_text2: 'Easily cancel within three months, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbrfJCkvkbxYUr8IE5SrCy' : 'price_1Oz31oJCkvkbxYUr0y3oG1HY',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-8B393129P34584742MYTUH4I'
            },
            {
                id: 'annual',
                name: '1 Year',
                name_planButton: '1 Year',
                amount: '$157.00',
                duration: 'for an annual subscription',
                recurring: true,
                savings: 'BEST VALUE',
                notice_text: 'for a 1-year Muzology subscription.',
                notice_text2: 'Easily cancel and you won’t be charged for the next annual subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbsCJCkvkbxYUrme71vLwC' : 'price_1Oz31mJCkvkbxYUrIcIVv86o',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-32E14694YU221724UMYTUGYA'
            }
        ]
    },
    {
        id: '200 students',
        name: 'Up to 200	students',
        recurring: true,
        plans: [
            {
                id: 'monthly',
                name: '1 Month',
                name_planButton: '1 Month',
                amount: '$21.99',
                duration: 'for a monthly subscription',
                recurring: true,
                savings: 'START NOW',
                notice_text: 'for a 1-month Muzology subscription.',
                notice_text2: 'Easily cancel within a month, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbsnJCkvkbxYUrURnXTO86' : 'price_1Oz31kJCkvkbxYUrlOHaQ7pl',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-99E01687AV363645XMYTTWMQ'
            },
            {
                id: '3month',
                name: '3 Months',
                name_planButton: '3 Months',
                amount: '$60',
                duration: 'for a 3 month subscription',
                recurring: true,
                savings: 'SAVE MORE!',
                notice_text: 'for a 3-month Muzology subscription.',
                notice_text2: 'Easily cancel within three months, and you won’t be charged for the next subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbtKJCkvkbxYUrhMYrd2Pz' : 'price_1Oz31jJCkvkbxYUr1hCuLiXX',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-4N2772622P209331AMYTUIDA'
            },
            {
                id: 'annual',
                name: '1 Year',
                name_planButton: '1 Year',
                amount: '$197',
                duration: 'for an annual subscription',
                recurring: true,
                savings: 'GREAT VALUE',
                notice_text: 'for a 1-year Muzology subscription.',
                notice_text2: 'Easily cancel and you won’t be charged for the next annual subscription period.',
                priceId: SANDBOX_MODE ? 'price_1OtbtkJCkvkbxYUrYFpxSEo8' : 'price_1Oz31dJCkvkbxYUrLAzan8ij',
                paypalPlanID: SANDBOX_MODE ? 'P-63K14984Y73523415MYTTZSA' : 'P-0RT79336L83066146MYTUG7A'
            }
        ]
    }
];
