// useDiscountCode.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useDiscountCode = (planId = null, initialCouponCode = null) => {
    // Normalize the initial coupon code value
    const initialCode = initialCouponCode === 'true' ? '' : initialCouponCode || '';

    const [couponCode, setCouponCode] = useState(initialCode);
    const [couponText, setCouponText] = useState('');
    const [couponIsLoading, setCouponIsLoading] = useState(false);
    const [discountObject, setDiscountObject] = useState(null);

    const updateCouponCode = async (code) => {
        if (!code) return;
        try {
            setCouponIsLoading(true);
            let url = `/api/discount-code/${code}/`;
            if (planId) {
                url = `/api/discount-code/${code}/${planId}/`;
            }
            const response = await axios.get(url);
            setCouponIsLoading(false);
            const coupon = response.data;
            setDiscountObject(coupon);
            setCouponText('');
            return coupon;
        } catch (error) {
            console.error('Error applying coupon code:', error);
            setCouponIsLoading(false);
            setDiscountObject(null);
            setCouponText('Discount code not valid: ' + code);
            return null;
        }
    };

    const handleCouponCodeChanged = (value) => {
        setCouponCode(value);
    };

    const handleBlurCouponCode = async () => {
        console.log('handleBlurCouponCode', couponCode);
        await updateCouponCode(couponCode);
    };

    // If an initial coupon code exists, update on mount
    useEffect(() => {
        if (couponCode) {
            updateCouponCode(couponCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        couponCode,
        setCouponCode,
        couponText,
        couponIsLoading,
        discountObject,
        updateCouponCode,
        handleCouponCodeChanged,
        handleBlurCouponCode,
    };
};

export default useDiscountCode;
