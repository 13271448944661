import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { SANDBOX_MODE, STRIPE_API_KEY } from '@/lib/defaults';
import CheckoutForm from './CheckoutForm';
import { StripePlans, StripeStudents } from './StripePlans';
import StripePlanButtons from '@/components/StripeCheckout/PlanButtons/StripePlanButtons';
import TimesTablePlanButton from './PlanButtons/TimesTablePlanButton';
import PayPalCheckoutButtons from '@/components/StripeCheckout/PayPalButtons';
import TeacherPlanButtons from './PlanButtons/TeacherPlanButtons';
import {
    NewInitialPaidTeacher, NewPaidTeacher, NewParentInitialPaid, NewParentPaid, NewParentTrial, TimesTablesNewPaidParent, TimesTablesPaidParent, TimesTablesRegisterParent
} from '@/analytics/events';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { useRouter } from 'next/router';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import axios from 'axios';
import cx from 'classnames';
import StripeField from '@/components/StripeCheckout/StripeField';
import useDiscountCode from '@/components/StripeCheckout/useDiscountCode';

// Load your publishable key from Stripe
const stripePromise = loadStripe(STRIPE_API_KEY);

interface CheckoutErrors {
    planId?: string;
    email?: string;
}

export const toQueryString = (params) => {
    return Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
};

// @ts-ignore
const StripeCheckout = ({
    id,
    sessionId,
    token,
    initialEmail = '',
    initialName = '',
    showPlanButtons = true,
    teacher = false,
    accountId = null,
    selectedStudents = null,
    selectedTeacherPlan = null,
    selectedParentPlan = null,
    setSelectedTeacherPlan = null,
    timesTables = null
}) => {
    const analytics = useAnalytics();
    const router = useRouter();
    const [email, setEmail] = useState(initialEmail);
    const [errors, setErrors] = useState<CheckoutErrors>({});
    const [name, setName] = React.useState(initialName);
    const [orderEmail, setOrderEmail] = React.useState(email);
    const [selectedPlan, setSelectedPlan] = useState(selectedParentPlan || 0);
    const [clientSecret, setClientSecret] = useState(null);
    const [intentId, setIntentId] = useState(null);

    const plan = useMemo(() => {
        if (timesTables) return StripePlans.find(p => p.id === 'times_tables');
        if (selectedPlan === null) return null;
        if (teacher) return StripeStudents[selectedStudents].plans[selectedTeacherPlan];
        return StripePlans[selectedPlan];
    }, [selectedPlan, selectedStudents, selectedTeacherPlan, teacher, timesTables]);

    const planId = useMemo(() => {
        if (timesTables) return StripePlans.find(p => p.id === 'times_tables').priceId;
        if (selectedPlan === null) return null;
        if (teacher) {
            const plan = StripeStudents[selectedStudents].plans[selectedTeacherPlan];
            return plan?.priceId;
        }
        const plan = StripePlans[selectedPlan];
        return plan?.priceId;
    }, [selectedPlan, selectedStudents, selectedTeacherPlan, teacher, timesTables]);

    console.log('plan', plan);
    console.log('planId', planId);

    // discount code
    const initialCouponCode = router.query.discount;
    const enableDiscountCode = router.query.discount !== undefined;

    const {
        couponCode,
        couponText,
        couponIsLoading,
        discountObject,
        handleCouponCodeChanged,
        handleBlurCouponCode,
    } = useDiscountCode(planId, initialCouponCode);

    // the client secret
    // const params = {
    //     // eslint-disable-next-line camelcase
    //     anonymous_id: analytics.getAnonymousId(), // eslint-disable-next-line camelcase
    //     plan_id: plan?.priceId, recurring: plan?.recurring, email: orderEmail, name
    // };
    // const queryString = toQueryString(params);

    // convert params toa url and query parameters
    // const { data: secretData } = useSWR(`/api/checkout/stripe/?${queryString}`, apiFetcher, {
    //     revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false
    // });
    // const clientSecret = useMemo(() => {
    //     console.log('StripeCheckout SecretData', secretData);
    //     if (sessionId) return sessionId;
    //     return secretData?.sessionId;
    // }, [sessionId, secretData]);


    // create the payment intent on page load
    /*
    useEffect(() => {
        const createPaymentIntent = async () => {
            const priceId = 'price_1NhxhvJCkvkbxYUrgdq1bvdT';
            try {
                // Replace with the actual amount or other necessary data
                const paymentData = {
                    name: 'Test User', email: 'test+elements@muzology.com', price: priceId
                };

                console.log('paymentData', paymentData);
                const response = await axios.post('/api/stripe/begin/', paymentData);
                console.log('response', response);
                if (response.data.clientSecret) {
                    console.log('Client secret received', response.data.clientSecret);
                    // setClientSecret(response.data.clientSecret);
                } else {
                    console.error('Client secret not received');
                }
            } catch (error) {
                console.error('Error creating payment intent:', error);
            }
        };
        createPaymentIntent().then(r => console.log(r)).catch(e => console.log(e));
    }, []);
    */

    // set up the strip element options
    const options = useMemo(() => {
        const appearance: Appearance = {
            theme: 'stripe', // theme: 'night',
            // theme: 'flat',
            labels: 'floating', // labels: 'above',
            variables: {
                colorPrimaryText: '#262626',
                colorPrimary: '#0570de',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: '#df1b41',
                fontFamily: 'Ideal Sans, system-ui, sans-serif' // borderRadius: '0'
                // spacingUnit: '4px',
                // borderRadius: '4px',
                // See all possible variables below
            }, rules: {
                '.TermsText': {
                    color: '#ffffff00'
                    // height: '0',
                    // display: 'none',
                }
            }
        };
        const options = {
            clientSecret: clientSecret as string, // mode: plan.recurring ? 'subscription' : 'payment',
            appearance
            // currency: 'usd',
            // amount: 1000,
            // layout: {
            //     type: 'tabs', defaultCollapsed: false,
            // }
        };
        if (plan?.recurring)
            options.clientSecret = clientSecret as string;
        return options;
    }, [clientSecret, plan?.recurring]);

    const handleSelectedPlan = (event, index, plan) => {
        setSelectedPlan(index);
    };
    const handelSelectTeacherPlan = (event, index, plan) => {
        setSelectedTeacherPlan(index);
    };

    // on successful completion - track event
    const onComplete = useCallback(async (source, data, callback = null) => {
        console.log('purchase OnComplete', source, data, timesTables);
        const options = {};
        // track the event
        const args = {
            ...router.query,
            flow: teacher ? 'teacher' : 'parent',
            name,
            email,
            source,
            planId,
            plan,
            // value: plan?.amount,
            price: plan?.amount,
            planName: plan?.name,
            purchase: data,
            testMode: SANDBOX_MODE
        };

        const valueArgs = {
            ...args, value: plan?.amount
        };

        if (timesTables) {
            // parent purchased a times tables subscription
            await analytics.track(TimesTablesPaidParent, valueArgs, options);
            await analytics.track(TimesTablesRegisterParent, args, options);
            await analytics.track(TimesTablesNewPaidParent, args, options);
        } else if (teacher) {
            // teacher purchased a paid subscription
            await analytics.track(NewInitialPaidTeacher, args, options);
            await analytics.track(NewPaidTeacher, args, options);
        } else {
            // parent purchased a paid subscription upgrade
            // await analytics.track(NewParentInitialPaid, valueArgs, options);
            // await analytics.track(NewParentPaid, valueArgs, options);
            // parent purchased a subscription with a trial
            await analytics.track(NewParentTrial, valueArgs, options);
        }

        if (callback) {
            callback();
        }

    }, [analytics, email, name, plan, planId, router.query, teacher, timesTables]);

    console.log('StripeCheckout Render', sessionId, teacher, selectedStudents, selectedTeacherPlan, selectedParentPlan, timesTables);

    // on mount load the client secret
    useEffect(() => {
        const params = {
            // eslint-disable-next-line camelcase
            anonymous_id: analytics.getAnonymousId(), // eslint-disable-next-line camelcase
            plan_id: plan?.priceId,
            recurring: plan?.recurring,
            email: orderEmail,
            accountId,
            name
        };

        // create the client secret & payment intent id
        const queryString = toQueryString(params);
        axios.get(`/api/checkout/stripe/?${queryString}`)
            .then(({ data }) => {
                console.log('StripeCheckout session:', data);
                const { sessionId, intentId } = data;
                setIntentId(intentId);
                setClientSecret(sessionId);
            })
            .catch(error => {
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // if (!clientSecret) return (
    //     <div className='h-[700px] relative'>
    //         <LoadingOverlay
    //             text=''
    //             show={true}
    //             className='z-50 bg-red-300 text-black'
    //             color='#00000060'
    //             style={{ background: '#00000000', color: 'black' }}
    //         />
    //     </div>
    // );

    const [isLoading, setLoading] = useState(false);

    return (
        <div className='min-h-[700px]'>
            {/*
            <div className='Backplate w-[347px] h-[132px] px-3 py-[9px] bg-white rounded-lg shadow border-2 border-neutral-400 flex-col justify-center items-start gap-2 inline-flex'>
            </div>
            */}
            {teacher && (
                <>
                    <TeacherPlanButtons selectedStudents={selectedStudents} selectedPlan={selectedTeacherPlan} setSelectedPlan={handelSelectTeacherPlan} />
                </>
            )}

            {!teacher && (
                <>
                    {timesTables ? (
                        <TimesTablePlanButton />
                    ) : (
                        <>
                            <StripePlanButtons selectedPlan={selectedPlan} setSelectedPlan={handleSelectedPlan} />
                            {errors.planId && <div className='mt-1 text-red-500 w-full text-left'>* Please select a plan</div>}
                        </>
                    )}
                </>
            )}

            {/* Stripe plans */}
            {/*
        {showPlanButtons && <div className='w-full mb-10'>
            <StripePlansField control={control} name='plan' rules={{ required: true }} />
             @ts-ignore
            {errors.plan && <div className='mt-1 text-red-500 w-full text-left'>* Please select a plan</div>}
        </div>}
        */}

            {/*
        <div className='EmailInput pb-6'>
            <label className='block text-base font-normal text-[#303030]'>Email</label>
            <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type='input'
                autoComplete='name'
                style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                className='block w-full px-4 py-2 mt-2 text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
            />
            {errors?.email && <div className='mt-1 text-red-500'> * {errors?.email}</div>}
        </div>
        */}

            <LoadingOverlay
                text=''
                show={isLoading}
                className='fixed'
                // color='#00000060'
                // style={{ background: '#00000000', color: 'black' }}
            />

            <div className='w-full max-w-[450px] m-auto mt-10 relative z-0'>
                {plan?.paypalPlanID && <PayPalCheckoutButtons
                    token={token}
                    accountId={accountId}
                    planId={plan?.paypalPlanID}
                    recurring={plan?.recurring}
                    sessionId={sessionId}
                    fundingSource='paypal'
                    name={name}
                    email={orderEmail}
                    onComplete={onComplete}
                    setLoading={setLoading}
                    discount={discountObject}
                    teacher={teacher}
                />}

                {!clientSecret && <div className='min-h-[400px] relative'>
                    <LoadingOverlay
                        text=''
                        show={!clientSecret}
                        className='z-50 h-[700px] text-black'
                        color='#00000060'
                        style={{ background: '#00000000', color: 'black' }}
                    />
                </div>}

                {enableDiscountCode && (
                    <>
                        <StripeField
                            name='coupon'
                            label='Discount Code'
                            value={couponCode}
                            onBlur={handleBlurCouponCode}
                            onChange={handleCouponCodeChanged}
                            className={cx('mb-4', { hidden: !enableDiscountCode })}
                            loading={couponIsLoading}
                            nextId='link-authentication-element'
                            // error={errors?.name}
                        />
                        {discountObject && <div className='m-0 p-4 pt-0 w-full text-left'>
                            Discount applied: <strong>{discountObject.discount} off!</strong>
                        </div>}
                        {couponText && <div className='m-0 p-4 pt-0 w-full text-left'>
                            {couponText}
                        </div>}
                    </>
                )}

                {clientSecret && <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                        id={id}
                        sessionId={sessionId}
                        intentId={intentId}
                        className=''
                        planId={planId}
                        email={orderEmail}
                        setEmail={setOrderEmail}
                        name={name}
                        setName={setName}
                        errors={errors}
                        setErrors={setErrors}
                        teacher={teacher}
                        accountId={accountId}
                        onComplete={onComplete}
                        timesTables={timesTables}
                        discount={discountObject}
                    />
                </Elements>}

            </div>
        </div>
    );
};

export default StripeCheckout;
