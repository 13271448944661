import React, { useState } from 'react';
import cx from 'classnames';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';

export const StripeField = ({ name, label, value, onChange, onBlur, className = '', error = null, loading = false, nextId = null }) => {
    const [focus, setFocus] = useState(false);
    const onFocus = () => setFocus(true);
    const [fieldValue, setFieldValue] = useState(value);

    const handleBlur = (event) => {
        setFocus(false);
        if (onChange)
            onChange(event.target.value);
        if (onBlur)
            onBlur(event);
    };

    const fieldClasses = cx('StripeField relative bg-[red]', className, {
        'StripeField-focus': focus, 'border-[#df1b41] border-solid border-2': error
    });
    const labelClasses = cx('StripeLabel', { 'StripeLabel-resting': !focus && !value });

    return (
        <>
            <div className={fieldClasses}>
                <label
                    className={labelClasses}
                    htmlFor={`Field-${name}-Input`}
                >
                    {label}
                </label>
                <input
                    id={`Field-${name}-Input`}
                    className='StripeInput w-full overflow-ellipsis font-normal p-[12px] pt-[32px] text-[16px] text-[#30313d] text-Stripe'
                    name={name}
                    // defaultValue={value ?? ''}
                    value={fieldValue}
                    onChange={(event) => {
                        setFieldValue(event.target.value);
                        if (onChange) {
                            onChange(event.target.value);
                        }
                    }}
                    // onChange={(event) => onChange(event.target.value)}
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        // nextId='Field-emailInput'
                        if (event.key === 'Enter') {
                            console.log('* ENTER PRESSED *');
                            event.preventDefault(); // Prevent default enter behavior (like form submission)
                            const target = event.target as HTMLInputElement;
                            target.blur?.(); // Blur the current input
                            // Attempt to move focus to the next form element
                            // const nextInputId = target.getAttribute('data-next-input');
                            // console.log("* NEXT INPUT ID *", nextInputId);
                            if (nextId) {
                                const nextElem = document.getElementById(nextId);
                                console.log('* NEXT INPUT ELEM *', nextId, nextElem);
                                if (nextElem) {
                                    nextElem.focus();
                                }
                            }

                            // console.log('form', event.target.form);
                            // const { form } = event.target;
                            // if (form) {
                            //     const index = Array.prototype.indexOf.call(form, event.target);
                            //     const next = form.elements[index + 1];
                            //     if (next) {
                            //         next.focus();
                            //     }
                            // }
                        }
                    }}
                />

                <LoadingOverlay
                    text={null}
                    show={loading}
                    color='#00000060'
                    style={{ background: '#00000060', color: 'black' }}
                />

            </div>
            {error && <div className='mt-[-10px] text-[#df1b41] text-[0.93rem] text-Stripe font-normal'>Please enter a name.</div>}
        </>
    );
};


export default StripeField;
