import React from 'react';
import Slider from 'react-slick';
import { Image } from 'react-datocms';
import { RatingBar } from './RatingBar';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface Props {
    testimonials: any;
    bgColor?: string;
}

export const TestimonialCarousel = ({ testimonials, bgColor }: Props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        centerMode: true,
        variableWidth: true,
        cssEase: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: false,
                },
            },
        ],
    };

    return (
        <div className='testimonial-carousel'>
            <div className='testimonial-carousel--wrapper'>
                {testimonials && testimonials.length > 0 && (
                    <Slider {...sliderSettings}>
                        {testimonials.map((testimonial, index) => {
                            const { name, organization, image, rating, quote } = testimonial;

                            return (
                                <div key={index}>
                                    <div className='testimonial-carousel--item'>
                                        <div className='testimonial-carousel--item-container'>
                                            <div className='testimonial-carousel--item-quote-container'>
                                                <img src='/assets/graphics/testimon-quote-mark.svg' alt='Quote' />
                                            </div>
                                            <div className='testimonial-carousel--item-content-container'>
                                                <h5 className='testimonial-carousel--item-quote'>
                                                    {quote}
                                                </h5>
                                                <div className='testimonial-carousel--item-name-container'>
                                                    <div className='testimonial-carousel--item-image'>
                                                        {image && <Image
                                                            data={{ ...image.responsiveImage, bgColor: '#ffffff00' }}
                                                            // LazyLoad={false}
                                                        />}
                                                    </div>
                                                    <div className='testimonial-carousel--item-person'>
                                                        <div className='testimonial-carousel--item-rating'>
                                                            <RatingBar rating={rating} size={20} />
                                                        </div>
                                                        <p className='testimonial-carousel--item-name'>
                                                            {name}
                                                        </p>
                                                        <p className='testimonial-carousel--item-organization'>
                                                            {organization}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                )}
            </div>
            <style jsx>
                {`

                    .testimonial-carousel {
                        width: 100%;
                        padding: 22px 0 97px;
                        background-color: ${bgColor || '#fff'};

                        .testimonial-carousel--wrapper {
                            margin: 0 -155px;
                        }

                        @media (max-width: 768px) {
                            padding: 22px 0 40px;
                        }

                        @media (max-width: 576px) {
                            padding: 22px 0 35px;
                        }


                        .testimonial-carousel--wrapper {
                            margin: 0 -155px;
                        }

                        .testimonial-carousel--item {
                            width: 682px;
                            height: 100%;
                            padding: 25px 22px 45px;

                            .testimonial-carousel--item-container {
                                background: #ffffff;
                                box-shadow: 0px 10px 45px rgba(176, 176, 176, 0.15);
                                border-radius: 20px;
                                padding: 58px 67px 54px 42px;
                                display: flex;
                                height: 100%;

                                .testimonial-carousel--item-quote-container {
                                    width: 50px;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .testimonial-carousel--item-name {
                                    margin-top: 7px;
                                    font-weight: 800;
                                }

                                .testimonial-carousel--item-content-container {
                                    flex: 1;
                                    padding-left: 27px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;

                                    .testimonial-carousel--item-quote {
                                        font-weight: 800;
                                        font-size: 24px;
                                        line-height: 28px;
                                        color: #4f4f4f;
                                        margin: 0;
                                    }

                                    .testimonial-carousel--item-name-container {
                                        margin-top: 43px;
                                        display: flex;
                                        align-items: center;

                                        .testimonial-carousel--item-image {
                                            width: 82px;

                                            img {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }

                                        .testimonial-carousel--item-person {
                                            flex: 1;
                                            padding-left: 20px;

                                            .testimonial-carousel--item-rating {
                                                width: fit-content;
                                            }

                                            .testimonial-carousel--item-name,
                                            .testimonial-carousel--item-organization {
                                                font-weight: 500;
                                                font-size: 18px;
                                                line-height: 25px;
                                                color: #333333;
                                                margin: 0;
                                            }

                                            .testimonial-carousel--item-name {
                                                margin-top: 7px;
                                                font-weight: 800;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: 768px) {
                            .testimonial-carousel--wrapper {
                                margin: 0;

                                .testimonial-carousel--item {
                                    width: 100%;
                                }
                            }
                        }

                        @media (max-width: 576px) {
                            .testimonial-carousel--wrapper {
                                .testimonial-carousel--item {
                                    padding: 25px 35px 45px;

                                    .testimonial-carousel--item-container {
                                        padding: 26px 23px;
                                        flex-direction: column;
                                        align-items: center;

                                        .testimonial-carousel--item-content-container {
                                            padding: 18px 0 0;

                                            .testimonial-carousel--item-quote {
                                                font-size: 18px;
                                                line-height: 22px;
                                            }

                                            .testimonial-carousel--item-name-container {
                                                margin-top: 3px;
                                                flex-direction: column;

                                                .testimonial-carousel--item-person {
                                                    padding: 15px 0 0;
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;

                                                    .testimonial-carousel--item-name,
                                                    .testimonial-carousel--item-organization {
                                                        margin-top: 9px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};
